"use strict";

exports.__esModule = true;
exports["default"] = void 0;

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

var isOfTypeObject = function isOfTypeObject(val) {
  return _typeof(val) === 'object';
};

var _default = isOfTypeObject;
exports["default"] = _default;